import React from "react"
import { PageProps, Link } from "gatsby"
import { Row, Col } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faServer,
  faChartLine,
  faDollarSign,
  faUserClock,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons"

import Layout from "../../components/PageLayout"
import SEO from "../../components/seo"
import Hero from "../../components/Hero"
import ContactForm from "../../components/ContactForm"
import SalesPoint from "../../components/SalesPoint"

const CloudConsultancy = (props: PageProps) => (
  <Layout
    hero={
      <Hero
        title="Serverless Consultancy"
        subtitle="Slash your costs and scale instantly with Serverless architecture."
        icon={
          <FontAwesomeIcon icon={faServer} color={"var(--white)"} size={"4x"} />
        }
      />
    }
  >
    <SEO
      title="Serverless Consultancy"
      description="Slash your costs and scale instantly with Serverless architecture."
    />
    <Row>
      <Col sm={12} md={12} lg={8}>
        <p className="lead">
          Serverless is a way of describing a new breed of cloud services that
          don't require the maintenance and associated cost of running
          traditional servers. Products like AWS Lambda, DynamoDB, Fargate and
          AWS IoT are examples of Serverless services. Their main attraction is
          low cost and high utilisation, as well as their ability to scale to
          meet demand easily.
        </p>

        <hr className="my-4" style={{ borderTopStyle: "dotted" }} />

        <SalesPoint icon={faChartLine} title={"Scale instantly"}>
          Traditional software deployments rely on application servers, usually
          known by name and scaled by hand. This leads to to low utilisation at
          quiet times and overloads when there is a lot of traffic. Serverless
          systems don't suffer from these problems. You can scale from one
          request to one million in seconds, all using the power of the cloud.
        </SalesPoint>

        <SalesPoint
          icon={faDollarSign}
          title="Save 99% over traditional server costs"
        >
          Because you only pay when your Serverless system is processing a
          request, it is effectively free when it is not being used. This can
          lead to significant savings over traditional deployments where servers
          are running 24x7.
        </SalesPoint>

        <SalesPoint
          icon={faUserClock}
          title={"Stop forcing your users to wait"}
        >
          There have been{" "}
          <a
            href="https://www.bristolpost.co.uk/whats-on/shopping/bq-queue-online-shopping-wait-4038028"
            target="_blank"
          >
            several
          </a>{" "}
          <a
            href="https://www.nme.com/news/music/glastonbury-76-1245096"
            target="_blank"
          >
            well publicised incidents
          </a>{" "}
          of popular websites imposing wait times or queues on their users to
          protect their infrastructure. This leads to a poor user experience and
          increased abandonment, impacting your bottom line. With Serverless,
          there is no fragile infrastructure to protect! Service your customers
          immediately.
        </SalesPoint>
      </Col>
      <Col>
        <ContactForm lead="Serverless might seem like black magic, but we can explain it simply and help you realise it's benefits." />
      </Col>
    </Row>
  </Layout>
)

export default CloudConsultancy
